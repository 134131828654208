.profile{
    min-width: 300px;
    max-width: 800px;
    background-color: white;
    border-radius: 15px;
    margin: 20px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.profile .title{
    text-align: center;
}
.profile .subtitle{
    font-size: 20px;
    font-weight: 600;
}
.profile .section-data{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.profile .section-data .profile-field {
    background-color: black;
    color-interpolation-filters: black;
    --PhoneInputCountryFlag-borderColor:black;
    --PhoneInputCountrySelectArrow-color:black;
    --PhoneInput-color--focus:black;
    color: black;
    padding: 10px;
}
.profile .description{
    font-size: 18px;
}
.profile input[type="text"], 
.profile .section-data .profile-field{
    background-color: black;
    border: none;
    padding: 10px;
    margin: 0px 5px;
    color: white;
    border-radius: 10px;
    flex:  1 2 0%;
}
.profile input[type="button"]{
    background-color: #4866f7;
    border: none;
    padding: 15px;
    color: white;
    font-size: large;
    font-weight: 600;
    border-radius: 25px;
}
.profile .subscriptions{
    background-color: #ddd;
    padding: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.profile .subscriptions > span {
    text-align: center;
    font-size: x-large;
    font-weight: 600;
}
.profile .subscriptions .subscription{
    background-color: #fff;
    padding: 15px;
    margin: 5px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.profile .subscriptions .subscription > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.profile .subscriptions .subscription p{
    font-weight: 600;
}
.profile .subscriptions .subscription span{
    font-weight: 400;
}
.profile .device-info{
    background-color: #ddd;
    padding: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.profile .device-info > span{
    text-align: center;
    margin-bottom: 5px;
    font-size: x-large;
}
.profile input[type='button']{
    cursor: pointer;
    margin-top: 15px;
}
.profile .delete-account {
    font-size: large;
    font-weight: 600;
    margin: 15px auto;
    color: red;
    cursor: pointer;
}
.profile .screenshots {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 15px;
}

.profile .screenshots span {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.profile .captures {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.profile .screenshot-image {
    width: calc(33.33% - 10px);
    flex: 1 1 auto;
    max-width: 100%;
    cursor: pointer;
}

.profile .screenshot-image img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.profile .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.profile .modal-content {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile .modal-content div{
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 5%;
    top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    border: 7px solid;
    color: white;
    box-shadow: 3px 3px 0px #666;
}
.profile .modal-content div span{
    font-size: 42px;
    font-weight: 700;
    text-shadow: 3px 3px 0px #666;
}
.profile .modal-content img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.profile .modal-content input {
    margin-top: 10px;
    width: 100%;
    height: 100px;
    height: fit-content;
    background-color: white;
    color: black;
}

.profile .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}
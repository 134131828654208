.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.modal-box {
  position: relative;
  width: 80%;
  margin: 0 10%;
  padding: 50px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  cursor: auto;
}
.modal-title {
  color: #4866f7;
  text-align: center;
  font-size: 30px;
}
.modal-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  transition: transform 250ms ease-in-out;
  cursor: pointer;
}
.modal-close-btn:hover {
  transform: rotate(180deg);
}
.modal-content {
  margin-top: 50px;
  margin: 20px 10px 0px;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
}
.modal-content span {
  font-size: large;
}
.modal-content input[type = 'Button'] {
  background-color: #4866f7;
  color: white;
  padding: 15px;
  margin: 15px;
  border: none;
  font-size: large;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
}
.modal-content input[type = 'text']{
  padding: 10px;
  margin: 10px 0;
  border-radius: 20px;
}
.modal
.modal-content .row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (min-width: 800px) {
  .modal-box {
    max-width: 600px;
    margin: 0 30px;
  }
}

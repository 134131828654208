.courses {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: auto;
    width: 90%;
}
.courses h1 {
    margin: 0px 0px 0px 20px;
}
.all-courses{
    display: flex;
    flex-direction: row;
    margin: 20px;
    justify-content: start;
    flex-wrap: wrap;
}
.courses .searcher{
    display: flex;
    margin: 10px auto;
    width: 50%;
}
.courses .searcher input{
    padding: 15px;
    margin: 5px;
    border-radius: 15px;
    border: none;
    font-size: medium;
}
.courses .searcher input[type='Text'] {
    width: 80%;
}
.courses .searcher input[type='Button'] {
    width: 20%;
    cursor: pointer;
}
.courses .searchResult{
    width: 100%;
}
.courses .category-data{
    margin-top: 30px;
}
.courses .category-data a{
    font-size: larger;
    color: black;
    text-decoration: none;
}
.courses .category-data a span{
    margin-left: 5px;
    font-weight: 700;
    text-decoration: underline;
}
.courses .courses-carousel {
    height: auto;
    overflow: auto;
    white-space: wrap;
    margin: auto;
    display: flex;
    flex-direction: row;
}
.courses .searchTitle{
    margin: 10px 0;
    font-size: large;
    font-weight: 600;
}
.courses .searchTitle span{
    font-style: italic;
    font-weight: 700;
}
.cases {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 10px;

}
.cases > div {
    margin: 10px 0px;
}
.cases .views div, .cases .content div {
    margin: 5px 5px;
}
.cases .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cases .cases-column {
    display: flex;
    flex-direction: column;
}
.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}
.grid-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
}
.copy-icon{
    background-color: #4866f7;
    border-radius: 26px;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-left: 10px;
    padding: 5px;
    cursor: pointer;
}
.content {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    height: auto;
}

.input[type=text], .input[type=number], .cases textarea, .cases select {
    background-color: black;
    border-radius: 10px;
    color: white;
    padding: 10px;
}
.input-large {
    height: 100%;
    margin: 10px 0px;
}
textarea {
    resize: none;
}
.views {
    height: auto;
}
.data {
    display: flex;
    justify-content: center;
}
.data span {
    align-items: center;
    font-size: medium;
    width: 250px;
}
.data .input {
    width: 100%;
    margin: 0px 10px;
}
.checkbox {
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.data span{
    align-items: center;
    font-size: medium;
    width: 250px;
}
.checkbox > div {
    margin: 0px 10px;
    display:flex; 
    justify-content:center; 
    width:100%;
}
.bg-white {
    background-color: white;
}
.bg-blue{
    background-color: #4866f7;
    color: white;
}

.bg-blue:hover{
    background-color: #3d56d1;
}
.bg-blue:active{
    background: linear-gradient(90deg,#7c1bc6,#461caf);
}
.large-button {
    padding: 20px;
    border-radius: 35px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.translate-container{
    height: 32px;
    width: 32px;
}
.translate-img{
    width: auto;
    padding: 0px;
}
.bg-white:hover{
    background-color: #bbb;
}
.thumbnail-image{
    width: 100%;
    display: flex;
    justify-content: center;
}
.width-100{
    width: 100%;
}

.width-90{
    width: 90%;
}
.width-50{
    width: 50%;
}
.width-25{
    width: 25%;
}
.justify-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.justify-start {
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.cases label {
    margin: 10px 0;
    font-size: x-large;
}
.m-h-10 {
    margin: 0 10px;
}
.m-v-10 {
    margin: 10px 0;
}
.m-10 {
    margin: 10px;
}
.end {
    display: flex;
    justify-content: end;
}
.center {
    display: flex;
    justify-content: center;
}
.justify-between {
    display: flex;
    justify-content: space-between;
}
.visible {
    color: #18cd00;
}
.invisible {
    color: rgb(197, 0, 0);
}
.position-relative{
    position: relative;
}
.close-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 30px;
    top: 30px;
    cursor: pointer;
}
.close-icon-2 {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -20px;
    top: 40%;
    cursor: pointer;
}
.no-margin {
    margin: 0 !important;
}
.bold {
    font-weight: bold;
}
.row2 {
    display:flex;
    margin: 0;
    padding: 0;
}
.cell2{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.cases .openCase-link{
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}
.floating-btn {
    position: fixed;
    bottom: 5%;
    right: 5%;
    font-size: x-large;
    padding: 20px !important;
}

@media (max-width: 1550px) {
    .grid-2{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
    .data{
        flex-direction: column;
    }
}
@media (max-width: 900px) {
    .grid-2{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
    .data{
        flex-direction: column;
    }
}
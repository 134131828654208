.viewUsers .title{
    margin: auto;
}
.viewUsers .user-data{
    width: 80%;
    margin: 15px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 25px;
}
.viewUsers .filter{
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
}
.viewUsers .filter select{
    margin-left: 10px;
    border: none;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
}
.viewUsers .filter button{
    background-color: black;
    color: white;
    border: none;
    padding: 10px;
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
}
.user-data .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    margin: 10px 0;
}
.user-data .subscriptions-user{
    background-color: #ddd;
    padding: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.user-data .subscriptions > p {
    text-align: center;
    font-size: x-large;
    font-weight: 600;
}
.user-data .subscriptions .subscription{
    background-color: #ddd;
    padding: 15px;
    margin: 5px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.user-data .subscriptions .subscription > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.user-data .subscriptions .subscription p{
    font-weight: 600;
}
.user-data .subscriptions .subscription span{
    font-weight: 400;
}
.partner{
    width: 80%;
    background-color: white;
    margin: 20px auto;
    padding: 20px;
    border-radius: 25px;
}
.partner .reference{
    display: flex;
    flex-direction: column;
}
.partner .reference span {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
}
.partner .reference .reference-data > * {
    margin: 10px;
    padding: 10px;
    font-size: large;
}
.partner .reference .reference-data input[type='button'] {
    color: white;
    border: none;
    background-color: #4866f7;
    width: 100px;
    cursor: pointer;
}
.partner .referenced{
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
}
.partner .referenced span{
    text-align: center;
    margin: 10px;
    font-size: large;
    font-weight: 600;
}
.partner .referenced table {
    
    border-collapse: 1px red;
}
.partner .referenced table thead th{
    background-color: black;
    color: white;
    text-align: start;
}
.partner .referenced table th{
    padding: 10px;
}
.partner .referenced table tr:nth-child(even) {
    background-color: #f2f2f2;
}
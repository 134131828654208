.suscriptions-page{
    display: flex;
    .button {
        border: none;
        margin: 15px;
        padding: 15px;
        border-radius: 25px;
        color: white;
        font-size: large;
        font-weight: 600;
        background-color: #2b74ff;
        cursor: pointer;
    }
    .button:hover {
        filter: brightness(0.85);
    }
}
.suscriptions-page .suscriptions-sections {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 100%;
    margin: 20px auto;
    padding: 20px;
}
.suscriptions-page .suscriptions-section{
    margin: 20px 0px;
    .section-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div{
            * {
                margin-right: 10px;
            }
            display: flex;
            input[type='text']{
                padding: 5px;
                border-radius: 5px;
                font-size: large;
            }
            img{
                background-color: #2b74ff;
                border-radius: 5px;
            }
        }
        img{
            width: 32px;
            height: 32px;
            cursor: pointer;
        }
        >img{
            filter: invert(100%) saturate(10000%);
        }

    }
}
.suscriptions-page .suscriptions-cases {
    overflow: auto;
    display: flex;
}
.suscriptions-page .suscriptions-case {
    min-width: 300px;
    height: 200px;
    background-color: black;
    margin: 10px;
    border-radius: 25px;
    position: relative;
    img:first-child {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
    }
    img:nth-child(2){
        position: absolute;
        top: 5%;
        right: 5%;
        width: 42px;
        cursor: pointer;
        transition: all 0.3s ease;
    }
    img:nth-child(2):hover{
        scale: 1.5;
    }
    span {
        position: absolute;
        color: white;
        margin: 10px;
    };  
    span:nth-child(1){
        font-weight: 600;
        font-size: larger;
        bottom: 15%;
    } 
    span:nth-child(2){
        bottom: 0%;
        font-size: medium;
    }   
}
.suscriptions-page .button-floating {
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 1;
}
.suscriptions-page .add-modal-container{
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;    
    background-color: rgba(0, 0, 0, 0.608);
}
.suscriptions-page .add-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 350px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    span{
        text-align: center;
        font-size: large;
        font-weight: 700;
    }
    .selects {
        display: flex;
        flex-direction: column;
        & * {
           margin: 5px; 
        }
        select {
            padding: 10px;
            font-size: large;
            font-weight: 600;
            cursor: pointer;
            border-radius: 15px;
        }
    }
    
    .buttons {
        * {
            margin: 5px;
            padding: 10px;
            border: none;
            font-size: large;
            font-weight: 600;
            cursor: pointer;
            border-radius: 15px;
        }
        *:hover{
            filter: brightness(85%);
        }
        .blue {
            background-color: #2b74ff;
            color: white;
        }
        display: flex;
        flex-direction: column;
    }
}
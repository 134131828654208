.course-card {
    margin: 10px;
}
.card-content {
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;
    min-height: 150px;
    min-width: 150px;
    max-width: 500px;
    max-height: 500px;
    background-color: black;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
.card-content.loaded {
    opacity: 1;
}
.card-image {
    width: 100%;
    object-fit: cover;
}
.card-title {
    font-size: 36px;
    font-weight: 700;
    position: absolute;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.49);
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    color: white;
    padding: 10px;
    margin: 0px;
}
.hidden-course-card {
    margin: 10px;
}
.hidden-course-card .card-content{
    min-height: 200px;
    max-height: 200px;
    min-width: 350px;
    max-width: 350px;
}
.hidden-course-card .card-title{
    font-size: 24px;
    height: 50px;
}
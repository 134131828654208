.password-recovery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px auto;
    width: 600px;
}
.password-recovery h1{
    margin: 20px;
    
}
.password-recovery span {
    margin: 10px;
    font-size: 18px;
    text-align: start;
    width: 100%;
}
.password-recovery input[type=text] {
    outline: none;
    background-color: #1c1328;
    color: white;
    border: none;
    padding: 15px;
    margin: 10px;
    border-radius: 30px;
    font-size: 16px;
    width: 100%;
}
.password-recovery input[type=text]::placeholder{
    color: white;
}
.password-recovery .recovery-email {
    display: flex;
    background-color: #4866f7;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 600px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}
.edit-users {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1500px;
}
.edit-users input[type="text"] {
    background-color: black;
    border-radius: 10px;
    color: white;
    padding: 10px;
}
.edit-users button {
    background-color: white;
    border-radius: 10px;
    color: black;
    border: none;
    outline-style: none;
    padding: 10px;
    cursor: pointer;
}
.edit-users button:hover{
    background-color: whitesmoke;
}
.access-control {
    width: 100px;
}
.edit-users .content > span {
    display: block;
}
.edit-users .content select{
    background-color: black;
    color: white;
    padding: 5px;
    margin: 0px 5px;
    border: none;
}
.edit-users .content input{
    border: solid 1px black;
    padding: 5px;
    margin: 0px 5px;
}

.visible {
    color: #18cd00;
    content: "ACTIVE";
}
.invisible {
    color: rgb(197, 0, 0);
    content: "EXPIRED";
}

.edit-users .referenced span{
    text-align: center;
    margin: 10px;
    font-size: large;
    font-weight: 600;
}
.edit-users .referenced{
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 30px;
}
.edit-users .referenced span{
    text-align: center;
}
.edit-users .referenced table {
    
    border-collapse: 1px red;
}
.edit-users .referenced table thead th{
    background-color: black;
    color: white;
    text-align: start;
}
.edit-users .referenced table th{
    padding: 10px;
}
.edit-users .referenced table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.edit-users .screenshots {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 15px;
}

.edit-users .screenshots span {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.edit-users .captures {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.edit-users .screenshot-image {
    width: calc(33.33% - 10px);
    flex: 1 1 auto;
    max-width: 100%;
    cursor: pointer;
}

.edit-users .screenshot-image img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.edit-users .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.edit-users .modal-content {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edit-users .modal-content .score{
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 5%;
    top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    border: 7px solid;
    color: white;
    box-shadow: 3px 3px 0px #666;
}
.edit-users .modal-content .score span{
    font-size: 42px;
    font-weight: 700;
    text-shadow: 3px 3px 0px #666;
}
.edit-users .modal-content img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.edit-users .modal-content .comment {
    width: 100%;
    display: flex;
}
.edit-users .modal-content .comment input[type="text"] {
    margin-top: 10px;
    height: 100px;
    height: fit-content;
    background-color: white;
    color: black;
}
.edit-users .modal-content .comment input[type="submit"] {
    background-color: #4866f7;
    color: white;
    border: none;
    font-size: large;
    font-weight: 600;
    border-radius: 25px;
    margin: 10px;
    width: 200px;
    cursor: pointer;
}
.edit-users .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}
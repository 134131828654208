.pricing {
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.pricing .card-pricing {
  width: 400px;
  height: 600px;
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pricing .card-pricing .card-data {
  display: flex;
  flex-direction: column;
}
.pricing .card-pricing .card-data p {
  font-size: 16px;
  margin: 5px;
  padding: 5px;
  text-align: center;
  font-weight: 600;
}
.pricing .card-pricing .card-data input {
  margin: 5px;
  padding: 5px;
  font-size: medium;
}
.pricing .card-pricing .card-buttons {
  display: flex;
  flex-direction: column;
}
.pricing .card-pricing .card-buttons input {
  margin: 5px;
  padding: 15px;
  border: none;
  background-color: #4866f7;
  color: white;
  font-size: large;
  font-weight: 600;
  border-radius: 25px;
}
.pricing .card-pricing .card-buttons input:hover {
  cursor: pointer;
}
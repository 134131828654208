.sections {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1500px;
}
.sections input[type="text"], .sections select {
    background-color: black;
    border-radius: 10px;
    color: white;
    padding: 10px;
}
.sections  .row {
    display: flex;
    flex-direction: row;
}
.sections  .column {
    display: flex;
    flex-direction: column;
}
.center {
    display: flex;
    justify-content: start;
    align-items: center;
}
.image-placeholder {
    width: 256px;
    height: 256px;
}
.large-button {
    padding: 10px;
    border-radius: 35px;
    text-align: center;
}
.large-button:hover{
    cursor: pointer;
}
.sections label {
    font-size: large;
    margin-right: 10px;
    font-weight: 600;
}
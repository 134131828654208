.new-home {
    background-color: black;
    color: white;
    width: 100%;
}

/* Background images */
.new-home .bg1, .new-home .bg2, .new-home .bg3 {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}
.new-home .bg1 {
    background-image: url("../../../public/header.png");
}
.new-home .bg2 {
    background-image: url("../../../public/doctor.png");
}
.new-home .bg3 {
    background-image: url("../../../public/compu.png");
}

/* Section 1 */
.new-home .section1 {
    padding: 30px;
}
.new-home .section1 .header-data {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 600px;
    /* margin: auto; */
}
.new-home .section1 .header-data > * {
    margin: 20px 0;
}
.new-home .section1 .header-data .title {
    font-size: 60px;
    width: 100%;
}
.new-home .section1 .header-data .desc {
    font-size: 32px;
    font-weight: 300;
    width: 100%;
}
.new-home .video {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 90%;
}
.new-home .section1 .information {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new-home .section1 .information > * {
    margin: 10px;
}
.new-home .section1 span {
    font-size: xx-large;
    font-weight: 500;
}
.new-home .section1 .information .entries{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.new-home .section1 .information .entries * {
    margin: 10px;
    padding: 10px;
    font-size: x-large;
    border-radius: 10px;

}

/* Section 2 */
.new-home .section2 {
    display: flex;
    flex-direction: column;
    padding: 30px;
    text-align: center;
}
.new-home .section2 .about {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new-home .section2 .about .title {
    font-size: 42px;
    max-width: 600px;
    margin: auto;
}
.new-home .section2 .about .desc {
    font-size: 40px;
    margin: 0 25px;
    text-align: center;
}

/* Section 3 */
.new-home .section3 {
    display: flex;
    flex-direction: column;
    padding: 30px;
}
.new-home .section3 .home-footer {
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -30px;
    padding: 20px;
}
.new-home .section3 .home-footer > img {
    width: 100%;
    max-width: 400px;
    margin: 20px;
}
.new-home .section3 .home-footer .downloads {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    width: 100%;
}
.new-home .section3 .home-footer .downloads .desktop, 
.new-home .section3 .home-footer .downloads .mobile {
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-size: x-large;
    text-align: center;
    margin: 10px 0;
    width: 50%;
    height: auto;
}
.new-home .section3 .home-footer .downloads .store-logo, 
.new-home .section3 .home-footer .downloads .qr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.new-home .section3 .home-footer .downloads .store-logo img{
    height: 80px;
    margin: 15px;
    cursor: pointer;
}
.new-home .section3 .home-footer .downloads .qr img {
    height: 180px;
    margin: 15px;
}

/* Courses Data */
.new-home .courses-data {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new-home .courses-data .frame {
    border: 5px solid white;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 20px 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new-home .courses-data .frame span {
    font-size: 42px;
    font-weight: 600;
    width: 40%;
    text-align: center;
}
.new-home .courses-data .frame ul {
    width:80%;
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    font-size: small;
}
.new-home .courses-data .frame li {
    font-size: 28px;
}
/* Linea */
.new-home .img-line{
    width: 100%;
    height: 20px;
}
/* Button */
.new-home .btn {
    background-color: #2b74ff;
    padding: 20px;
    border-radius: 50px;
    font-size: x-large;
    text-align: center;
    margin: auto;
    cursor: pointer;
}
.floating{
    display: flex;
    position: fixed;
    right: 20px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 10;
    transition: 0.9s ease all;
}
.bottom300{
    bottom: 300px;
}
.bottom20{
    bottom: 20px;
}
.floating img {
    width: 80px;
    height: 80px;
    position: absolute;
}
/* Floating Container */
.floating-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 20px;
    right: 20px;
    width: 350px;
    max-width: 90%;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 2;
}
.floating-container img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.floating-container > * {
    margin: 5px 0;
}
.floating-container > p {
    text-align: center;
    font-size: larger;
    color: black;
}
.floating-container > textarea {
    padding: 5px;
    font-size: large;
}
.floating-container > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.floating-container > input[type='text']{
    font-size: large;
    padding: 5px;
}
.floating-container input[type='button'] {
    font-size: large;
    background-color: #4866f7;
    padding: 10px;
    border-radius: 20px;
    margin: 5px;
    border: none;
    color: white;
    cursor: pointer;
}
/* Media Queries */

@media (max-width: 1100px) {
    .new-home .section1 .header-data .title {
        font-size: 42px;
    }
    .new-home .section1 .header-data .desc {
        font-size: 28px;
    }
    .new-home .section2 .about .title {
        font-size: 48px;
    }
    .new-home .section2 .about .desc {
        font-size: 32px;
    }
    .new-home .courses-data .frame span {
        font-size: 40px;
    }
    .new-home .courses-data .frame li {
        font-size: 28px;
    }
    .new-home .btn {
        padding: 15px;
        font-size: large;
    }
    .new-home .section1 .information .entries{
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .new-home .section1 .header-data {
        width: 90%;
    }
    .new-home .section1 .header-data .title {
        font-size: 48px;
    }
    .new-home .section1 .header-data .desc {
        font-size: 24px;
    }
    .new-home .section2 .about .title {
        font-size: 32px;
    }
    .new-home .section2 .about .desc {
        font-size: 24px;
    }
    .new-home .courses-data .frame span {
        font-size: 28px;
    }
    .new-home .courses-data .frame li {
        font-size: 28px;
    }
    .new-home .btn {
        padding: 10px;
        font-size: medium;
    }
    .floating-container {
        width: 90%;
    }
    .new-home .section3 .home-footer .downloads  {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .new-home .section1 .header-data .title {
        font-size: 32px;
    }
    .new-home .section1 .header-data .desc {
        font-size: 18px;
    }
    .new-home .section2 .about .title {
        font-size: 24px;
    }
    .new-home .section2 .about .desc {
        font-size: 18px;
    }
    .new-home .courses-data .frame span {
        font-size: 20px;
    }
    .new-home .courses-data .frame li {
        font-size: 18px;
    }
    .new-home .btn {
        padding: 8px;
        font-size: small;
    }
}
.categories-page {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1500px;
}
.categories-page input[type="text"], .categories-page select {
    background-color: black;
    border-radius: 10px;
    color: white;
    padding: 10px;
}

.categories-page  .column {
    display: flex;
    flex-direction: column;
}
.row {
    display: flex;
    flex-direction: row;
}
.center {
    display: flex;
    justify-content: start;
    align-items: center;
}
.categories-page .image-placeholder {
    width: 256px;
    height: 256px;
}
.large-button {
    padding: 10px;
    border-radius: 35px;
    text-align: center;
}
.bg-red {
    background: linear-gradient(90deg,#ff3b3b,#a00000);
    color: white;
}
.bg-red:hover {
    background: linear-gradient(90deg,#d13131,#5e0101);
    color: white;
}
.accordion {
  max-width: 600px;
  margin: 2rem auto;
  font-size: 20px;
}
.accordion-item {
  background-color: white;
  border-radius: 10px;
  margin: 15px 0;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.accordion-title div {
  font-size: 18px;
  font-weight: bold;
}
.accordion-title {
  padding: 1rem;
}

@media screen and (max-width: 700px) {
  .accordion {
    width: 90%;
  }
}

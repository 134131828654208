.admin {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 500px;
    margin: auto;
}
.admin .button a {
    color: white;
    font-size: x-large;
    font-weight: 600;
    display: flex;
    justify-content: center;
    text-decoration: none;
}
.admin .button {
    background-color: #4866f7;
    padding: 10px;
    margin: 10px;
    border-radius: 25px;
}
.reports{
    width: 80%;
    margin: auto;
}
.reports > h1 {
    margin: 15px;
    text-align: center;
}
.report{
    background-color: white;
    padding: 20px;
    margin: 10px;
    border-radius: 20px;
}
.report-header, .report-footer {
    display: flex;
    justify-content: space-between;
}
.report-header span{
    font-size: large;
    font-weight: 600;
}
.report-header img{
    cursor: pointer;
    width: 16px;
    height: 16px;
}
.report-body{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    color: #555;
}
.report-footer{
    font-style: italic;
}
.terms-area{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.terms-content{
    max-width: 1300px;
    display: flex;
    flex-direction: column;
}
.terms-title{
    display: flex;
    width: 100%;
    color: #9e7924;
    font-size: 25px;
    font-weight: 400;
}
.green-link {
    color: #4866f7;
    display: inline;
    font-weight: 700;
    text-decoration: underline;
}
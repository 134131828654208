.course-template .head {
  width: 80%;
  height: 300px;
  margin: auto;
}
.course-template .head-container {
  margin: auto;
  width: 80%;
  height: 300px;
  border-radius: 25px;
  text-align: center;
  overflow: hidden;
  position: absolute;
}
.course-template .head-container img {
  height: 100%;
  filter: blur(0.5rem);
}
.course-template .head-container div {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 33%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-template .head-container div span {
  font-size: xx-large;
  font-weight: bold;
}
.course-template .categories {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 50px auto;
}
.course-template .categories .category-data {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
}
.course-template .categories .category-data img {
  width: 32px;
  height: 32px;
}
.course-template .categories .category-data span {
  font-size: large;
  font-weight: 600;
}
.course-template .categories .courses-carousel {
  width: 100%;
  height: auto;
  overflow: auto;
  white-space: nowrap;
  margin: auto;
  display: flex;
  flex-direction: row;
}
.case-card {
  background-color: black;
  margin: 10px;
  color: white;
  background-position: center;
  border-radius: 15px;
}
.case-card:hover{
    cursor: pointer;
}
.case-card .case-content {
    width: 420px;
    height: 240px;
    position: relative;
}
.case-card .case-content > span {
    background-color: #4866f7;
    padding: 3px;
    font-size: x-large;
    position: absolute;
    top: 20px;
}
.case-card .case-data {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    padding: 10px;
}
.case-card .case-data .case-title {
    font-size: x-large;
    font-weight: 700;
    text-wrap: pretty;
}
.case-card .case-data .case-description {
    font-size: large;
    font-weight: 600;
    text-wrap: pretty;
}
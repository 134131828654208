.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
  width: 400px;
  background-color: white;
  padding: 20px;
  text-align: center;
  border-radius: 30px;
  margin: 20px;
  position: relative;
}
.card .info {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.card .title {
  font-size: x-large;
  font-weight: 600;
  margin: 10px;
}
.card .price {
  font-size: xx-large;
  font-weight: 500;
  margin: 10px;
}
.card .duration {
  font-size: x-large;
  font-weight: 500;
  margin: 10px;
}
.card .float-admin{
  position: absolute;
  top: 0%;
}
.card .only-admin {
  padding: 10px 0px;
  border-radius: 30px 30px 0px 0px;
  background-color: rgba(120, 120, 120, 0.555);
  right: 50%;
  transform: translate(50%, 0);
  width: 100%;
}
.card .button-admin{
  width: 42px;
  height: 42px;
  padding: 8px;
  border-radius: 30px;
  right: 0%;
  transform: translate(50%, 0);
  cursor: pointer;
}
.card .delete {
  background-color: rgb(221, 0, 0);
}
.card .edit {
  background-color: #4866f7;
  top: 12.5%;
}
.card .card-admin span {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 7px;
  font-size: large;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0%;
  color: black;
  overflow: hidden;
  border-radius: 30px 30px 0 0;
}
.card .card-admin .edit-admin {
  background-color: #4866f7;
  padding: 3px 8px;
  left: 0;
  border-radius: 30px 0 0 0;
}
.card .type {
  margin: 10px;
}
.card .description {
  margin: 10px;
}
.card .get-button {
  border: none;
  background-color: #4866f7;
  margin: 10px;
  padding: 15px;
  border-radius: 25px;
  color: white;
  font-size: large;
  font-weight: 600;
}
.card .get-button:hover{
  cursor: pointer;
}
.card .coupon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.card .coupon span {
  font-size: large;
  font-weight: 600;
}
.card .coupon input {
  display: block;
  margin: 5px;
  padding: 5px;
  width: 100%;
  font-size: large;
}
.card .premium-btn {
  background-color: #2c2e2f;
  color: white;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}
.card .edit-data {
  display: flex;
  flex-direction: column;
  margin: 5px;
}
.card .edit-data > * {
  margin: 5px;
  padding: 5px;
}

.card .card-data {
  display: flex;
  flex-direction: column;
}
.card .card-data > * {
  margin: 5px;
  padding: 5px;
  font-size: medium;
}
.card .card-data > p {
  font-weight: 700;
}
.card .card-data input[type="button"]{
  background-color: #4866f7;
  color: white;
  border: none;
  padding: 20px;
}
.premium{
  z-index: 0;
}
.card .loader {
  margin: 0;
  padding: 0;
  right: 0;
  top: 0;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}
.cases-manager{
    margin: 20px auto;
    width: 90%;

    .select-cases {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
    }
    .cases-buttons{
        display: flex;
        justify-content: space-between;
        
        flex-wrap: wrap;
        gap: 10px;
        
        .img-buttons{
            width: 100%;
            display: none;
            justify-content: space-around;
            align-items: center;
            & > * {
                margin: 0 5px;
            }
        }
        .buttons, .language, .case-id{
            display: flex;
            align-items: center;
            & > * {
                margin: 0 5px;
            }
        }
    }
    .basic-information{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        .basic{
            background-color: white;
            padding: 20px;
            border-radius: 20px;
            min-width: 45%;
            flex: 1;
            
        }
    }
    .views{
        .view {
            background-color: white;
            padding: 20px;
            border-radius: 20px;
            .view-header{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            .view-body{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .view-basic{
                min-width: 200px;
                padding: 15px;
                flex: 1;
            }
        }
    }
    .wrap{
        flex-wrap: wrap;
    }
    .field {
        display: flex;
        align-items: center;
        gap: 5px;
        flex: 1;
        margin: 10px 0;
        min-width: 150px;
        span{
            flex: 1;
            max-width: 150px;
        }
        input, select{
            flex-grow: 1;
            min-width: 0;
        }
        textarea{
            background-color: black;
            color: white;
            border: none;
            border-radius: 10px;
            font-size: large;
            height: 76px;
            flex-grow: 1;
            min-width: 0;
            padding: 5px 10px;
            resize: none;
        }
    }
    .select-field {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px;
        flex: 1;
        min-width: 300px;
        max-width: 100%;
    }
    .select-field span{
        width: 140px;
        font-size: large;
        font-weight: 600;
    }
    .select-field select {
        flex-grow: 1;
        min-width: 0;
        padding: 10px;
    }
    select{
        border: none;
        border-radius: 10px;
        background-color: black;
        color: white;
        font-size: large;
        padding: 5px 10px;
        width: auto;
    }
    input[type="text"], input[type="number"]{
        background-color: black;
        border: none;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: large;
        color: white;
        height: 25px;
    }
    input[type="text"]{
        min-width: 50px;
    }
    input[type="number"]{
        width: 50px;
        min-width: 20px;
    }
    .img-button{
        height: 32px;
        background-color: #4866f7;
        padding: 5px;
        border-radius: 20px;
        cursor: pointer;
    }
    .custom-button{
        flex: 1;
        flex-wrap: wrap;
        min-width: auto;
        font-size: large;
        font-weight: 600;
        color: white;
        padding: 15px;
        border: none;
        cursor: pointer;
        background-color: #4866f7;
        border-radius: 125px;
        transition: all 0.2s ease;
    }
    .custom-button:hover{
        filter: brightness(0.75);
    }
    .red{
        background-color: rgb(218, 23, 23);
    }
    .green {
        background-color: rgb(28, 154, 28);
    }
}
@media (max-width: 800px) {
    .buttons{
        display: none !important;
    }
    .img-buttons {
        display: flex !important;
    }
}
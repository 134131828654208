.learning {
    display: flex;
    flex-direction: column;
}
.learning h1 {
  text-align: center;
}
.learning .learning-courses {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 20px auto;
  overflow: scroll hidden;
}
.learning .learning-course {
    cursor: pointer;
  width: 800px;
  height: 450px;
  margin: 5px 20px;
  background-color: black;
  border-radius: 25px;
  position: relative;
}

.learning .learning-course img {
  width: 800px;
  height: 450px;
  object-fit: cover;
}
.learning .learning-course span {
  color: white;
  background-color: rgba(0, 0, 0, 0.482);
  font-size: xx-large;
  font-weight: 650;
  width: 100%;
  height: 15%;
  border-radius: 0px 0px 25px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0%;
  right: 50%;
  transform: translate(50%, 0);
}

/* Estilo básico para el scroll */
.learning ::-webkit-scrollbar {
  width: 20px; /* Ancho del scroll */
  height: 20px;
}

.learning ::-webkit-scrollbar-thumb {
  background-color: rgb(0, 223, 170); /* Color del thumb */
  border-radius: 12px; /* Bordes redondeados */
}

.learning ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 141, 108);; /* Color cuando el mouse pasa sobre el thumb */
}

.learning ::-webkit-scrollbar-track {
  background-color: #ddd; /* Color del fondo del track */
}

.title h1 {
    margin: 30px;
    text-align: center;
    font-size: xx-large;
}
.questions {
    width: 55%;
    margin: auto;
    border-radius: 50px;
}
.question {
    text-align: center;
    margin: auto;
}
.answer {
    font-size: 16px;
    text-align: left;
    padding: 15px;
}

.minimum-requirements {
    margin: 20px;
    display: flex;
    flex-direction: column;
}
.minimum-requirements h1 {
    margin-bottom: 25px;
}
.requiriments {
    display: flex;
    justify-content: space-around;
}
.specs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    margin-top: 10px;
}
.specs span{
    font-weight: bold;
    margin: 10px 0;
}
.specs ul {
    margin-left: 25px;
}
.pc img {
    height: 180px;
}
.browsers span {
    font-weight: bold;
}
.browsers img {
    height: 100px;
    margin: 15px;
}

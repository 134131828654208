.footer {
    margin: 10px;
    display: flex;
    justify-content: center;
}
.footer  a {
    width: 100%;
    font-size: 18px;
    padding: 10px 0;
    color: #4866f7;
    font-weight: 700;
}
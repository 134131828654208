body {
  background-color: #ddd;
}
.header {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo img {
  width: 280px;
}
.header .categories {
  display: flex;
  align-items: center;
}
.header .nav-link {
  margin: 0px 20px;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: large;
}
.header .settings {
  width: 280px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.header .language-icon {
  width: 18px;
}
.settings select {
  background-color: transparent;
  padding: 5px;
  border: none;
  font-size: large;
  color: white;
}
.settings select * {
  background-color: black;
  color: white;
}
.settings span {
  color: white;
}
.account {
  display: flex;
  align-items: center;
  margin: 10px;
}
.account img {
  margin-left: 10px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.account a {
  text-decoration: none;
  color: #888;
}
.account span {
  cursor: pointer;
}
.header .openCase-Link {
  cursor: pointer;
}

@media (max-width: 1250px) {
  .header {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .categories {
    flex-direction: column;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown img {
    width: 24px;
    height: 24px;
    margin: 0px;
}
.dropdown-toggle {
  background-color: transparent;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 120%;
  right: -22%;
  background-color: black;
  color: white;
  width: 150px;
  list-style: none;
  padding: 5px 0px;
  margin: 0;
  z-index: 1;
}

.dropdown-menu a {
    color: white !important;
}
.dropdown-menu li {
  padding: 10px 15px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #181818;
}
